<template>
  <window-content v-if="!preventBind" class="financeiro-window">
    <div class="w-m-header">
      <div style="line-height: 1">
        <h2 v-if="!member.id">Novo Membro</h2>
        <h2 v-else>Membro</h2>
        <div class="m-t-n-xs">{{project.name}}</div>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <pessoa-cadastro ref="cadastro" />
      <div class="body-content" style="max-width: 500px !important;">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes do membro</legend>
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 100px; min-height: 32px">
            <erp-s-field view="ll" :label="!member.active ? 'Membro desativado' : 'Ativo'">
              <sl-checkbox no-yes-no-val v-model="member.active" id="active"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col>
            <erp-s-field label="Pessoa">
              <person-select autofocus v-model="member.person" />
            </erp-s-field>
            <div style="display: flex; justify-content: space-between; align-items: center">
              <div class="m-t-xs text-right font-11"><a @click="gotoPerson(member.person)">Visualizar pessoa</a></div>
              <div class="m-t-xs text-right font-11"><a @click="$refs.cadastro.show()">Cadastrar pessoa</a></div>
            </div>
          </e-col>
        </e-row>
        <e-row class="m-t-sm" mr>
          <e-col>
            <erp-s-field label="Papel">
              <member-select v-model="member.aclGroup" />
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern window-actions">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Voltar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar Membro" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right__"/>
<!--        <u-btn icon="sort-down" icon-type="fa" no-caps class="p-btn-down normal b-radius-3px font-12 text-white">
          <u-popover ref="popover" class="window-context-menu" style="width: 192px" anchor="bottom right"
                     self="top right" :offset="[0, 0]">
            <div>
              <ul>
                <menu-options-item @click="save('andEdit')" label="Salvar e continuar editando" close/>
                <menu-options-item @click="save('andNew')" label="Salvar e criar novo" close/>
              </ul>
            </div>
          </u-popover>
        </u-btn>-->
      </div>
    </div>
  </window-content>
</template>

<script>
import {UTooltip, UPopover, debounce} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'
import {newMember, updateMember, findMember} from "@/domain/project/services/members"
import mockMember from "@/domain/project/helpers/mockMember"
import SlCheckbox from "@/reuse/input/Checkbox"
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect"
import MemberSelect from "components/suporte/components/projetos/include/MemberGroupSelect"
import windowPessoa from "components/pessoa/windows/simpleNewPerson"
import PessoaCadastro from "components/pessoa/components/window/Cadastro"
import GlobalPersonMixin from "components/pessoa/globalPersonMixin"

export default {
  name: 'MemberWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [GlobalPersonMixin],
  components: {
    PessoaCadastro,
    MemberSelect,
    //ErpSelect,
    PersonSelect,
    SlCheckbox,
    //ErpInput,
    ErpSField,
    ERow,
    ECol,
    UTooltip,
    WindowContent
  },
  props: ['id', 'options', 'router', 'project'],
  data() {
    return {
      loading: false,
      editing: false,
      success: false,
      status: '',
      preventBind: true,
      member: JSON.parse(JSON.stringify(mockMember))
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {},
  computed: {
  },
  methods: {
    load() {
      this.loading = true
      findMember(this.project.id, this.id)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              this.member.id = response.data.id
            }
            this.preventBind = true
            this.member = Object.assign({}, this.member, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            console.log(this.member)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.member))
      const relacoes = ['person', 'aclGroup']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })
      return data
    },
    prepareToEdit(data) {
      return data
    },
    async save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      const submit = () => {
        let method = newMember
        if (this.member.id) {
          method = updateMember
        }
        method(this.project.id, data)
            .then(response => {
              this.loading = false
              console.log(response)
              const isEditing = this.isEditing
              this.member.id = response.data.id
              if (this.id) {
                this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
                this.success = true
                this.dg()
                this.$uloc.notify({
                  color: 'positive',
                  message: `Membro ${this.member.person.name} alterado.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              } else {
                this.$uloc.window.emit(this.$root.wid, 'created', response.data)
                this.$uloc.notify({
                  color: 'black',
                  message: `#${response.data.person.name} ${this.id ? 'modificado' : 'criado'}.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
                response.data._userCreated && this.$uloc.dialog({
                  title: 'Usuário criado',
                  message: `Foi criado um novo acesso com o login ${response.data._userCreated.username} e a senha ${response.data._userCreated.password}.`,
                  ok: 'Ok',
                  noCaps: true
                }).then(() => {

                }).catch(() => {})
                this.$uloc.window.close(this.$root.wid)
              }
              if (type === 'andNew') {
                this.preventBind = true
                this.member = JSON.parse(JSON.stringify(mockMember))
                this.$nextTick(() => {
                  this.preventBind = false
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.alertApiError(error)
            })
      }
      submit()
    },
    windowPessoa: windowPessoa
  }
}
</script>
